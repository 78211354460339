/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Code Highlight */
@import "../node_modules/prismjs/themes/prism-coy.css";

@font-face {
    font-family: "Sceptica";
    src: url("/assets/layout/fonts/Sceptica.otf") format("opentype");
}

@import "assets/layout/styles/theme/ng-multiselect-dropdown.theme.scss";

// DANGER TEXT FOR VALIDATION FIELDS
.text-danger {
    color: red;
}

.clean-toolbar {
    background-color: var(--white);
    border: none;
}

.p-inputtext {
    border-radius: 0;
}

.searcher {
    width: 60%;
}
